import { FC, useEffect, useState } from 'react';
import useShowMore from '@/utils/useShowMore';
import Button from './button';
import Video from './video';
import { useResponsive } from '@/utils/useResponsive';
import { BOOK_DEMO_URL, VIDEO_URL } from '@/config-global';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const text =
  'Nestflo AI-powered virtual robot automates repetitive tasks, empowers fast response times, and captures new business opportunities for estate agencies.';

const Hero: FC = () => {
  const [limit, setLimit] = useState(0);
  const { toggle, isMore } = useShowMore(text, limit);
  const { isMobile } = useResponsive();
  const [tooltipOpen, setTooltipOpen] = useState(false); // For Tooltip control

  useEffect(() => {
    if (isMobile) {
      setLimit(40);
    } else {
      setLimit(70);
    }
  }, [isMobile]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipOpen(true); // Show tooltip when copy is successful
        setTimeout(() => {
          setTooltipOpen(false); // Hide tooltip after 2 seconds
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false); // Close tooltip on click away
  };

  const handleBookDemoClick = () => {
    console.log(BOOK_DEMO_URL);
    window.location.href = BOOK_DEMO_URL; // 使用原生的 window.location.href 进行页面跳转
  };

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative">
        <picture className="pointer-events-none absolute inset-0 -z-20 overflow-hidden">
          <img
            src="/assets/background/bg_hero_375.png"
            alt="dark"
            className="h-auto w-full sm:hidden"
          />
          <video autoPlay loop muted className="hidden h-auto w-full sm:block">
            <source src="/assets/video/bg_hero.mp4" type="video/mp4" />
          </video>
        </picture>
        <div className="container pb-20 pt-16 xl:pt-32">
          <div className="mx-auto max-w-6xl pt-20 lg:pt-36">
            <div className="lg:w-4/5">
              <h1 className="font-display mb-10 text-4xl font-light leading-[50px] tracking-tighter text-white sm:text-[40px] md:text-[50px] lg:text-6xl lg:leading-[70px]">
                Automate client interaction
              </h1>
              <p className="text-sm font-medium leading-[28px] tracking-tighter sm:text-base lg:text-base">
                <span className="align-bottom">{text}</span>
                {/* Copy icon with Tooltip */}
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    title="Copied!"
                    open={tooltipOpen}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="right"
                  >
                    <img
                      src="/assets/icons/copy.svg"
                      alt="Copy icon"
                      className="ml-2 inline-block cursor-pointer"
                      onClick={handleCopy}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </Tooltip>
                </ClickAwayListener>
                <span
                  className="ml-2 hidden cursor-pointer align-bottom font-medium text-orange"
                  onClick={toggle}
                >
                  {isMore ? 'Show less' : 'Show more'}
                </span>
              </p>
              <div className="flex items-center justify-center lg:justify-start">
                <Button
                  className="mt-24 bg-gradient-to-r from-blue via-brown to-orange px-8"
                  style={{
                    marginRight: '50px'
                  }}
                  onClick={handleBookDemoClick} // 处理 Book Demo 按钮点击事件
                >
                  Book Demo
                </Button>
                <Video
                  target={
                    <Button
                      className="mt-24 bg-gradient-to-r from-blue via-brown to-orange px-8"
                      style={{
                        background:
                          'linear-gradient(to right, #210863, #490BD1, #6B0BE9)'
                      }}
                    >
                      Watch video story
                    </Button>
                  }
                  url={VIDEO_URL}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
