import React from 'react';
import Video from './video';
import { BrainIcon, CloudIcon, ComputerIcon, IdeaIcon } from '@/assets/icons';
import { HowWorksShadow } from '@/assets/shadow';
import useShowMore from '@/utils/useShowMore';
import { Card, Head, Paragraph, SubHead } from '@/components/card';
import { Container, Content, Cta, Section, Title } from '@/components/section';
import { VIDEO_URL } from '@/config-global';
import Link from 'next/link';
interface DataProps {
  title: string;
  icon: React.ReactNode;
  subTitle: string;
  description: string;
}

const Item: React.FC<{ item: DataProps }> = ({ item }) => {
  const { toggle, isMore } = useShowMore(item.description, 100);
  return (
    <Card>
      <div className="absolute -left-8 top-6 h-1 w-36 bg-gradient-to-r from-[#2D009E] via-[#B3304A] to-[#EF4F34]" />
      <div className="absolute right-6 top-6">{item.icon}</div>
      <Head className="mt-4 pr-14">{item.title}</Head>
      {item.subTitle && <SubHead>{item.subTitle}</SubHead>}
      <Paragraph className="mt-6">
        {/* {str} */}
        {item.description}
      </Paragraph>
      <div className="mt-9 hidden w-full items-center justify-end">
        <button
          className="leading-0 cursor-pointer align-bottom font-medium text-orange"
          onClick={toggle}
        >
          {isMore ? 'Show less' : 'Show more'}
        </button>
      </div>
    </Card>
  );
};

const Process: React.FC = () => {
  const data: DataProps[] = [
    {
      title: 'Offering a white labelled solution',
      subTitle: '',
      description:
        'We provide white labelled software, which means you can use Nestflo with your logo and your branding details – impressing your customers with the new features!',
      icon: <IdeaIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'Easy onboarding',
      subTitle: '',
      description:
        'Getting started with Nestflo is as simple as three steps and takes no more than 10 minutes. You do not need to manually input property data or load data from your main CRM or Excel sheet.',
      icon: <BrainIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'No integrations',
      subTitle: '',
      description:
        'Our system does not need integrations or downloads to provide a customised back office and AI-powered virtual robot for your agency.',
      icon: <ComputerIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'Using letting-focused natural language abilities',
      subTitle: '',
      description:
        'We use AI Natural Language Understanding, AI Natural Language Processing, and AI Machine learning. These AI technologies enable our AI solution to provide these services seamlessly and autonomously in the letting agent industry.',
      icon: <CloudIcon className="w-12 shrink-0 xl:w-14" />
    }
  ];
  return (
    <Section id="link_4">
      <picture className="pointer-events-none absolute inset-x-0 top-[-600px] -z-20 opacity-60">
        <HowWorksShadow className="h-auto w-full" />
      </picture>
      <Container>
        <Title
          text="How Nestflo works"
          className="mx-auto w-full max-w-[1200px] font-medium"
        />
        <Content>
          <div className="mx-auto w-full max-w-[1200px]">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 md:gap-12">
              {data.map((item, index) => (
                <Item item={item} key={index} />
              ))}
            </div>
          </div>
        </Content>
        <div className="flex w-full flex-col items-center">
          <Link
            href="https://calendly.com/roland-tao-55/30min?month=2024-02"
            target="_blank"
          >
            <Cta>Book a demo</Cta>
          </Link>
        </div>
      </Container>
    </Section>
  );
};

export default Process;
